* {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}


body {
    background: linear-gradient(180deg, #195397 0%, #103771 100%);;
    color: #494949;
    font-size: .875vw;
    box-sizing: border-box;
}

.main-wrapper {
    max-width: 600px;
    margin: 0 auto;
}

.page-header {
    width: 100%;
    position: relative;
    margin-bottom: 30vw;
}

.page-header img {
    width: 100%;
    object-fit: contain;
}

.tire-path {
    position: absolute;
    bottom: -18%;
    left: 0;
}

.main-content {
    padding: 0 12vw;
}

.ios-instruction {
    display: none;
}

.android-instruction {
    display: none;
}

.instruction-step {
    position: relative;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    margin-bottom: 3vw;
}

.step-numper {
    width: 1.6rem;
    height: 1.6rem;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    position: absolute;
    top: 0;
    left: 0;
}

.instruction-step img {
    width: 100%;
    object-fit: contain;
    padding-top: 5vw;
}

.ar-button {
    color: inherit;
    background-color: #eee;
    border: 4px solid #fff;
    border-radius: 29px;
    margin: 20vw 7vw 25vw;
    padding: 1.2rem 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    text-decoration: none;
    display: none;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 18px #00000059;
}

.ar-button span {
    margin-left: 1.6rem;
}

.show-content {
    display: block;
}

.show-button {
    display: flex;
}


@media (min-width: 1000px) {

    .page-header {
        margin-bottom: 12vw;
    }
    
    .ar-button {
        margin: 5vw 2vw;
    }
}